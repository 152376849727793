import { assert } from "./assert";
export class SingleSelectionProxy {
    getItem(value, group) {
        return group.find((selectableItem) => selectableItem.value === value);
    }
    /**
     * Gets called when a value is selected. This function simulates a user interaction.
     *
     * @param value The value of an item.
     * @param group The group containing all items.
     * @return An array of all selected values
     */
    selectValue(value, group) {
        const item = this.getItem(value, group);
        return this.selectItem(item, group);
    }
    /**
     * Selects the given value and ignores disabled items.
     *
     * @param value The value of an item.
     * @param group The group containing all items.
     * @return An array of all selected values
     */
    forceSelectValue(value, group) {
        group.forEach((groupItem) => {
            groupItem.internalSelected = groupItem.value === value;
        });
        return value;
    }
    /**
     * Validates the current selection.
     *
     * @param group The group containing all items.
     * @param additionalMessages An array of objects getting logged with the validation messages.
     */
    checkSelection(group, ...additionalMessages) {
        singleSelectCheckSelection(group, additionalMessages);
    }
}
export class SingleToggleSelection extends SingleSelectionProxy {
    selectItem(item, group) {
        group.forEach((groupItem) => {
            groupItem.internalSelected = item === groupItem && !(item === null || item === void 0 ? void 0 : item.internalSelected);
        });
        return getSingleSelectedItemValue(group);
    }
}
export class SingleSelection extends SingleSelectionProxy {
    selectItem(item, group) {
        group.forEach((groupItem) => {
            groupItem.internalSelected = item === groupItem;
        });
        return getSingleSelectedItemValue(group);
    }
}
function getSingleSelectedItemValue(group) {
    var _a;
    return (_a = group.find((groupItem) => groupItem.internalSelected)) === null || _a === void 0 ? void 0 : _a.value;
}
function singleSelectCheckSelection(group, ...additionalMessages) {
    const selectedItems = group.filter((item) => item.internalSelected);
    const selectedItemLength = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.length;
    assert(selectedItemLength === 0 || selectedItemLength === 1, () => `You must not set multiple items as selected!`, additionalMessages);
}
export const POSSIBLE_ICON_SIZES = [24, 64];
